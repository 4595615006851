.form-field {
  position: relative;
}
​ .form-field label {
  position: absolute;
  left: 10px;
  top: -12px;
  width: max-content;
  transition: all 0.2s ease-in-out;
  background: #fff;
  color: #4c6acc;
  padding: 3px 7px;
  border-radius: 0px;
  font-size: 12px;
  pointer-events: none;
}
​ .form-field input,
.form-field select {
  background: #fff;
  border: 1px solid #ccc;
  color: #000;
  font-size: 14px;
  padding: 5px 10px;
  height: 40px;
  border-radius: 2px;
}
​ .form-field input:focus,
.form-field select:focus {
  border: 1px solid #4c6acc !important;
  outline: 1px solid #4c6acc;
  -webkit-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}
​ .profile-update-card {
  background-color: #fff;
  box-shadow: 1px 1px 10px rgb(236, 236, 236);
  min-height: 300px;
}
.profile-bg {
  background-color: #4c6acc;
  height: 150px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}
.user-image {
  width: 100%;
  height: 284px;
  background: linear-gradient(#1269db 55%, #ffffff 41%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.user-image img {
  width: 150px;
  height: 150px;
  margin: auto;
  object-fit: cover;
  border-radius: 65%;
  position: relative;
}
.change-photo {
  display: flex;
  align-self: center;
  position: absolute;
  text-align: center;
  bottom: 10px;
  visibility: hidden;
}
.profile-update-card .btn {
  font-size: 14px;
}
.profile-update-card .card-title {
  font-size: 18px;
  margin-bottom: 30px;
}
.card-container {
  box-shadow: 2px 6px 15px 0 rgb(69 65 78 / 10%);
  background-color: white;
  border-radius: 10px;
  width: 100%;
  height: 95%;
}
.table-card-container {
  border-radius: 10px;
  box-shadow: 2px 6px 15px 0 rgb(69 65 78 / 10%);
  margin-bottom: 20px;
  overflow: auto;
  height: 95%;
}
.card {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 9%);
  width: 78vw;
  height: 50vh;
  border: none;
  margin-top: 18px;
}
.card.profile-update-card {
  height: 93%;
  width: 95%;
  margin-left: 8px;
}
.form-group input {
  padding: 0.3rem 1rem !important;
}
.card-header {
  background: #fff;
  border-bottom: 1px solid rgb(228, 226, 226);
}
.change {
  /* background: #0d6efd !important; */
  padding: 4px !important;
  margin-top: -26px !important;
  color: white;
  border-radius: 2px;
  width: 50%;
  display: block;
}
.user-image .file input {
  position: absolute;
  opacity: 0;
  left: 32%;
}
.file:hover label {
  color: #fff;
}
