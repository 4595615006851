.sector-wrapper {
  box-shadow: 2px 6px 15px 0 rgb(69 65 78 / 10%);
}

.sector-card {
  height: 270px;
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.sector-card p {
  color: #575962;
}
.sector-card::before {
  position: absolute;
  height: calc(90%);
  width: 1px;
  background: #eee;
  content: "";
  right: -11px;
  top: 12px;
}

.sector-card.last::before{
    content: unset;
}

.sector-settings{
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
}

.sector-card:hover .sector-settings{
    opacity: 1;
    pointer-events: all;
}