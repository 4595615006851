.table-card-container {
  border-radius: 10px;
  box-shadow: 2px 6px 15px 0 rgb(69 65 78 / 10%);
  margin-bottom: 20px;
  overflow: auto;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 5px 5px!important;
  font-size: 13px;
}
