/* .sidebar-container {
  position: fixed;
  width: 70px;
  top: 60px;
  left: 0;
  height: 90vh;
  overflow: auto;
  z-index: 5;
}
.sidebar-container.hide {
  width: 250px;
}
.sidebar-container.hide .ant-menu-title-content {
  display: block;
}
.sidebar-container .ant-menu-title-content {
  display: none;
}
.sidebar-container > div > p {
  display: none;
}
.sidebar-container.hide > div > p {
  display: block;
}

.sidebar-header {
  float: left;
  width: 70px;
  height: 62px;
  line-height: 60px;
  color: #333;
  z-index: 1001;
  font-size: 17px;
  font-weight: 400;
  padding-left: 25px;
  padding-right: 25px;
  z-index: 100;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s;
}
.sidebar-header.hide {
  width: 250px;
}

.sidebar-header.hide > img {
  display: block;
}
.sidebar-header > img {
  display: none;
}
.sidebar-container ul {
  border-bottom: 1px solid #ececec;
} */
.sidebar-container {
  position: fixed;
  width: 250px;
  top: 60px;
  left: 0;
  height: 90vh;
  overflow: auto;
  z-index: 5;
}

.sidebar-header {
  float: left;
  width: 250px;
  height: 62px;
  line-height: 60px;
  color: #333;
  z-index: 1001;
  font-size: 17px;
  font-weight: 400;
  padding-left: 25px;
  padding-right: 25px;
  z-index: 100;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s;
}
.sidebar-container ul {
  border-bottom: 1px solid #ececec;
}
