.login-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.login-status{
  text-align: center;
}
.logout{
  text-align: center;
  height: 40px;
  background: #d4edda;
  color: #155724
}
.logout-text{
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 400;
}