@import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600&family=Raleway:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fira Sans", sans-serif;
  /* transition: 0.3s !important; */
}

p {
  cursor: context-menu;
}

.ant-tabs-tab-btn {
  color: #107bdd;
}

.ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #f25961 !important;
}

.ant-tabs-ink-bar {
  background: #f25961 !important;
}

.form-control {
  font-size: 15px !important;
}

.fw-200 {
  font-weight: 200;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.vh-100 {
  height: 100vh;
}

.img-logo {
  width: 100px;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.ant-input-affix-wrapper {
  padding: 0.7rem 1rem !important;
  border-radius: 4px !important;
}

.ant-input {
  padding-left: 5px !important;
}

.text-999 {
  color: #999999;
}

.text-666 {
  color: #666666;
}

.text-ff7f27 {
  color: #ff7f27;
}

.login-form-container {
  width: 90%;
  margin: auto;
  max-width: 300px;
}

.bg-1572e8 {
  background-color: #1572e8;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #adadad;
}

.ant-menu-item {
  display: flex !important;
  align-items: center;
}

.main-container {
  position: fixed;
  top: 60px;
  left: 250px;
  background: #f9fbfd;
  width: calc(100% - 250px);
  height: 94vh;
  overflow: auto;
}

.heading-container {
  background: linear-gradient(-45deg, #06418e, #1572e8) !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.content-wrapper {
  position: relative;
  bottom: 30px;
}

.table-icons {
  cursor: pointer;
}

.content-container {
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 2px 6px 15px 0 rgb(69 65 78 / 10%);
}

hr {
  /* background: #ddd;
  margin: 0 !important; */
  position: absolute;
}

.table-icons.edit-icon {
  border: 1px solid #ffad46 !important;
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 50%;
}

.table-icons.delete-icon {
  border: 1px solid #f25961 !important;
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 50%;
  margin-left: 5px;
}

.ant-btn-round.ant-btn-lg {
  display: flex;
  align-items: center;
}

.ant-picker {
  padding: 6px 12px !important;
}

.ant-select-selector {
  padding: 3px !important;
  border: none !important;
}

@media screen and (max-width: 767px) {
  .hide-sm-and-down {
    display: none !important;
  }
}

.action-button {
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.text-secondary {
  color: #6861ce !important;
}

.text-success {
  color: #31ce36 !important;
}

.text-warning {
  color: #ffad46 !important;
}

.text-danger {
  color: #f25961 !important;
}

.main-content-wrapper {
  background-color: white;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  margin: 1rem 3rem 2rem 3rem;
  box-shadow: 2px 6px 15px 0 rgb(69 65 78 / 10%);
}

.no-shadow {
  box-shadow: none !important;
}

.ant-table-tbody > tr > td {
  min-width: 70px !important;
}

.ant-table {
  overflow: auto;
}

.sidebar-item,
.ant-menu-item-selected {
  color: #8d9498 !important;
}

.sidebar-item:hover {
  color: #575962 !important;
  background-color: #f4f4f4 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-item-selected {
  background-color: white !important;
}

.profile-img {
  width: 2.5rem !important;
  height: 2.5rem !important;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.ant-modal-body {
  padding-top: 15px !important;
}

.sidebar-header.show,
.sidebar-container.show {
  transform: translateX(0) !important;
  z-index: 999;
}

.nav-menu-icon,
.sidebar-menu-icon {
  display: none;
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  overflow: auto;
}

.sidebar-menu-icon:hover {
  background: #00000010;
}

.nav-menu-icon:hover {
  background: #00000010;
}

.sidebar-header,
.sidebar-container {
  transition: 0.3s !important;
}

.btn-danger {
  background-color: #f25961 !important;
  border-color: #f25961 !important;
}

.btn-danger:hover {
  background-color: #e43e46 !important;
  border-color: #e43e46 !important;
}

@media screen and (max-width: 1000px) {
  .sidebar-header,
  .sidebar-container {
    transform: translateX(-250px);
  }

  .nav-menu-icon,
  .sidebar-menu-icon {
    display: inline;
  }

  .navbar-container,
  .main-container {
    width: 100% !important;
    left: 0 !important;
  }

  .content-wrapper {
    padding: 15px !important;
  }

  .px-5 {
    padding: 15px !important;
  }

  .main-content-wrapper {
    margin: 1rem !important;
  }
}
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}

.goog-te-gadget-icon {
  display: none;
}

.goog-te-gadget-simple {
  background-color: #ecebf0 !important;
  border: 0 !important;
  font-size: 10pt;
  font-weight: 800;
  display: inline-block;
  padding: 5px 5px !important;
  cursor: pointer;
  zoom: 1;
}

.goog-te-gadget-simple span {
  color: #3e3065 !important;
}
