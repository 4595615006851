.navbar-container {
  position: fixed;
  top: 0;
  left: 250px;
  width: calc(100% - 250px);
  /* height: 6vh; */
  background-color: #1269db;
  box-shadow: 0 0 5px rgb(18 23 39 / 50%);
  z-index: 20;
  padding: 0px;
  min-height: 62px;
}

.user-detail-container {
  display: flex;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 3px 3px 10px 0px #1890ff54;
  backdrop-filter: blur(10px);
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  right: 50px;
  top: 62px;
  padding: 1rem 2rem;
  transition: 0.2s;
  max-width: 90%;
  margin: auto;
  opacity: 1;
}

.user-detail-container.hide {
  opacity: 0;
  pointer-events: none;
}

.user-detail-container img {
  width: 120px;
  border-radius: 50%;
  overflow: hidden;
  height: 120px;
}

.logout-btn-container button {
  margin-top: 8px;
}

.user-detail-container p {
  margin: 6px auto !important;
}

.cursor-pointer {
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .user-detail-container {
    width: 90%;
    right: 5%;
    flex-direction: column;
  }

  .user-detail-container .text-primary {
    display: inline-block;
    padding-right: 20px;
  }
}
.input-group {
  display: flex;
  border-radius: 5px;
  border: none;
  background: rgba(0, 0, 0, 0.14);
  box-shadow: 3px 3px 6px 0 rgb(0 0 0 / 7%);
  transition: all 0.4s;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 310px !important;
}
.memo-input {
  border: none;
  outline: none;
  background: 0 0 !important;
  font-size: 14px;
  padding: 0.75em 2em;
  min-width: 200px;
  max-width: 100%;
  font-size: 13px;
  font-weight: 400;
}
.memo-input::placeholder {
  color: #fff;
  font-weight: 300;
}
/* .memo-input:focus {
  border: none;
  border-radius: 5px !important;
  background: #fff !important;
} */
/* .memo-input:focus::placeholder {
  color: rgb(214, 210, 210) !important;
} */

.pr-1 {
  padding-right: 0.25rem !important;
}
.view-memo-btn {
  font-size: "13px";
  font-weight: "400";
  background: #1572e8 !important;
  border: #1572e8 !important;
  padding: 7px !important;
  height: 30% !important;
  width: 47%;
  right: 7px;
}
.btn.btn-search.pr-1 {
  color: white;
  border: none;
  left: 48px;
}
.goog-te-gadget {
  font-size: 19px !important;
}
.goog-te-gadget-simple {
  background-color: #0dcaf0 !important;
  border: none !important;
}
.goog-te-gadget-icon {
  display: none !important;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
  margin-bottom: 2p;
  margin-top: -18px;
}
.ant-modal-body {
  padding: none;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.language-btn {
  background-color: #0f5abc !important;
  color: #fff;
  padding: 6px 14px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 3px;
  margin-right: 20px;
}
.trigger-btn:focus {
  outline: none;
  box-shadow: none;
}
@media only screen and (max-width: 1024px) {
  .input-group {
    right: 15%;
    height: auto;
    width: 270px !important;
  }
  .view-memo-btn {
    right: 11%;
  }
  .profile {
    position: absolute;
    right: 9px;
  }
  .language-btn {
    right: 27%;
  }
  .trigger-btn {
    position: absolute;
    right: 20px;
  }
  .btn.btn-search.pr-1 {
    left: 0;
  }
}

@media only screen and (max-width: 1000px) {
  .nav-menu-icon {
    position: relative;
    left: -32px;
  }
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}
.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.dropdown-user {
  width: 260px;
}
.dropdown-menu {
  border: 0;
  border-radius: 3px;
  box-shadow: 0 1px 11px rgba(0, 0, 0, 0.15) !important;
  padding-bottom: 8px;
  top: 13px !important;
}
.user-box {
  display: flex;
  padding: 0.25rem 1rem;
}
.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}
button.logout-btn {
  height: 22px;
  padding: 0;
  font-size: 12px;
}
.user-box .u-text {
  padding: 0 10px;
}
.user-box .u-text .text-muted {
  font-size: 12px;
  margin-bottom: 5px;
}
.dropdown-menu:after {
  border-bottom: 8px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  content: "";
  right: 10px;
  top: -8px;
  position: absolute;
  z-index: 1001;
}

.setting-icon {
  position: absolute;
  right: 20px;
  top: 3px;
}
.input-group.focus {
  background-color: #fff;
}
.input-group.focus .trigger-btn > span {
  color: #000;
}
.input-group.focus > input::placeholder {
  color: #000;
}
