span.ant-picker-separator {
  display: none;
}

span.ant-picker-suffix {
  display: none;
}
.ant-picker-range .ant-picker-clear {
  display: none;
}
.ant-picker-active-bar {
  display: none;
}
.ant-picker-input {
  width: 20%;
}
.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none !important;
}

.ant-picker-panel-container,
.ant-picker-footer {
  width: 280px !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}
.company-task-modal .ant-modal-body {
  padding: 20px 0px !important;
}
