.card-container{
    box-shadow: 2px 6px 15px 0 rgb(69 65 78 / 10%);
    background-color: white;
    border-radius: 10px;
}

.card-main-icon {
    cursor: pointer;
    color: white;
    width: 45px;
    height: 45px;
    padding: 0.8rem;
    margin-right: 1rem;
    transition: border-radius 1s;
  }
.card-main-icon:hover{
    border-radius: 100%;
    overflow: hidden;
}